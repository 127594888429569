.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: white;
}
.navbar {
  background-color: #282c34;
}
.navbar-icon {
  border-radius: 100px;
}
.header-text {
  letter-spacing: 10px;
  font-size: 40px;
  margin-bottom: 20px;
}
.container {
  /* background-color: rgb(255, 0, 0); */
  padding: 80px 160px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 60vw;
}
.container h2 {
  margin-block-start: 0px;
  margin-block-end: 0px;
  font-size: 30px;
  color: #282c34;
}
.text-border {
  border-style: solid;
  border-radius: 10px;
  border-color: #282c34;
  border-width: 20px;
  padding: 10% 30%;
  max-width: 40vw;
}
.text {
  text-align: center;
}

.colorChangeText1 {
  display: none;
  color: white;
  text-align: center;
  padding-top: 100px;
  border-bottom: 2px solid;
}
.colorChangeText2 {
  display: block;
  color: white;
  text-align: center;
  padding-top: 100px;

  border-bottom: 2px solid;
}

@media only screen and (max-width: 600px) {
  .header-text {
    letter-spacing: 10px;
    font-size: 40px;
    margin-bottom: 20px;
  }
  .container {
    /* background-color: rgb(255, 0, 0); */
    padding: 100px 80px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 60vw;
  }
  .container h2 {
    margin-block-start: 0px;
    margin-block-end: 0px;
    font-size: 20px;
    color: #282c34;
  }
  .text-border {
    border-style: solid;
    border-radius: 10px;
    border-color: #282c34;
    border-width: 10px;
    padding: 50% 15%;
    max-width: 40vw;
  }
  .text {
    text-align: center;
  }
  .colorChangeText1 {
    display: block;
    padding-top: 80px;
  }
  .colorChangeText2 {
    display: none;
  }
}

@media only screen and (max-width: 400px) {
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0px;
    color: white;
    justify-content: unset;
  }
  .header-text {
    letter-spacing: 8px;
    font-size: 40px;

    margin-bottom: 20px;
  }
  .container {
    /* background-color: rgb(255, 0, 0); */
    padding: 100px 80px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 60vw;
  }
  .container h2 {
    margin-block-start: 0px;
    margin-block-end: 0px;
    font-size: 20px;
    color: #282c34;
  }
  .text-border {
    border-style: solid;
    border-radius: 10px;
    border-color: #282c34;
    border-width: 10px;
    padding: 50% 15%;
    max-width: 40vw;
  }
  .text {
    text-align: center;
  }
  .colorChangeText1 {
    display: block;
    padding-top: 80px;
  }
  .colorChangeText2 {
    display: none;
  }
}
